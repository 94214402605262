<template>
  <div style="height:300px; width: 300px; display: flex; flex-direction:column;">
    <vue3-chart-js v-if="renderComponent"
        :id="doughnutChart.id"
        ref="chartRef"
        :type="doughnutChart.type"
        :data="doughnutChart.data"
        width="300px"
    ></vue3-chart-js>
  </div>
</template>
  
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ref } from 'vue'

export default {
  name: 'App',
  data(){
    return{
        shouldRender: false,
        renderComponent: true,
    }
  },
  props:{
    labels: Array,
    data: Array,
  },
  components: {
    Vue3ChartJs,
  },
  watch:{
    data(){
        if(this.data.length > 0){
            this.doughnutChart.data.labels = this.labels
            this.doughnutChart.data.datasets[0].data = this.data
            this.forceRerender()
            
        }
    },
},
  setup (props) {
    const chartRef = ref(null)
    const doughnutChart = {
      id: 'doughnut',
      type: 'doughnut',
      // width:'300px',
      data: {
        labels: props.labels,
        datasets: [
          {
            backgroundColor: [
            "#ffbe0bff", "#219ebc", "#fb5607ff", "#f94144ff", "#f3722cff", "#f8961eff", "#f9844aff", "#f9c74fff", "#90be6dff", "#43aa8bff", "#4d908eff", "#577590ff", "#277da1ff"
              
            ],
            data: props.data
          }
        ]
      }
    }
    return {
      doughnutChart,
      chartRef
    }
  },methods:{
    forceRerender() {
        // Removing my-component from the DOM
        this.renderComponent = false;
        this.$nextTick(() => {
          // Adding the component back in
          this.renderComponent = true;
        });
      }
    }
}
</script>

<style scoped lang="scss">

</style>
