<template>
        <div class="parent">
            <!-- <h3>{{ dayDate.toString().substring(0,15) }}</h3> -->
            <LineChart :labels="pieLabels" :dataSet1="set1" :dataSet2="set2" :cats="cats" />
        </div>
</template>

<script>
  import {api} from "@/helpers/api";
  import createdMixin from "@/components/_mixins/createdMixin";
  import LineChart from "@/components/Totals/LineChart.vue";
  
  export default {
    name: 'Totals',
    mixins: [createdMixin],
    components: { LineChart },
    props:['dateBegin', 'dateEnd'],
    data() {
      return {
        daysPayments: null,
        pieLabels: [],
        set1: [],
        set2: [],
        dayDate: new Date(),
        currDateOffSet: new Date().getTimezoneOffset() / 60,
        cats: ['Today','Last Week'],
      }
    },
    async created(){
      //this.getTotals();
      this.getPayments();
    },
    watch:{
        dayDate(){
            this.getPayments();
        }
    },
    methods:{
      async apiCall(type, startDate, endDate, group, group_level){
        return await api.get(`/api/reports/${type}/${localStorage.getItem("company")}_${localStorage.getItem("site")}?group=${group}&group_level=${group_level}&start_key=["${startDate}"]&end_key=["${endDate}"]`)
      },
      async getPayments(){
        let start =new Date(this.dayDate.setHours(0,0,0));
        let end = new Date(this.dayDate.setHours(23,59,59));

        let lastWeekTemp = this.getLastWeeksDate();
        let lastWeekStart = new Date(lastWeekTemp.setHours(0,0,0));
        let lastWeekEnd = new Date(lastWeekTemp.setHours(23,59,59));

        let payments = await this.apiCall('sales',start.toISOString().substring(0,13),end.toISOString().substring(0,13), true, 1)
        let lastWeekPayments = await this.apiCall('sales',lastWeekStart.toISOString().substring(0,13),lastWeekEnd.toISOString().substring(0,13), true, 1)

        this.lastWeekPayments = this.reducerByKey(lastWeekPayments);
        this.todaysPayments = this.reducerByKey(payments);

        this.pieLabels = Object.keys(this.lastWeekPayments).map((key)=>{
          return (parseInt(key.substring(11,13)) - this.currDateOffSet) > 24 ? parseInt(key.substring(11,13)) - this.currDateOffSet - 24 : parseInt(key.substring(11,13)) - this.currDateOffSet;
        });

        this.set1 = Object.values(this.todaysPayments);
        this.set2 = Object.values(this.lastWeekPayments);
      },
      reducerByKey(reduceArray){
        const reducedArray = reduceArray.rows.reduce((acc, payment) => {
          const value = acc[payment.key[1]] ?? 0;
          return {...acc, [payment.key[0]]: value + payment.value};
        }, {});
        return reducedArray;
      },
      getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      }
    }
  }
  </script>
  
  <style scoped lang="scss">

  .parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .date{
        width: 100%;
    }
  }
  
  </style>
