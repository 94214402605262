<template>
  <Authenticated>
    <div class="actions">
      <h2>Reports</h2>
    </div>
    <div class="totals-body">
      <div class="date">{{ new Date().toString().substring(0,15) }}</div>
      <div class="graphs">
        <div class = "dayTotal" >
          <ChartParent />
        </div>
      </div>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import ChartParent from '@/components/Totals/ChartParent.vue';

export default {
  name: 'Totals',
  mixins: [createdMixin],
  components: { Authenticated, ChartParent},
  data() {
    return {
      todayStart: null,
      todayEnd: null,
      todaysTotal:null,
      show:false
    }
  },
  mounted(){
    this.$store.commit('currPage', 'reports')
  },

  async created() {
    this.todayStart = new Date();
    this.todayStart.setHours(0,0,0);
    this.todayEnd = new Date();
    this.todayEnd.setHours(23,59,59);
  }
}

</script>

<style scoped lang="scss">
@import "public/actions";
.totals-body{
  margin: 0rem 0rem;
  padding: 0rem 1rem;
  overflow: hidden;

  .date{
    margin: 1rem 0rem;
    font-size: 2rem;
  }
    .graphs{
      display: grid;
      grid-template-areas: "";
      align-items: center;
      gap: 10px;
    }

    .dayTotal{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
    }
}

</style>
