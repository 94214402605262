<template>
  <teleport to="body">
    <div
      v-if="openModal"
      class="modal"
      @click.self.prevent="openModal != openModal"
    >
      <div class="content">
        <font-awesome-icon
          icon="fa-circle-xmark"
          class="exit"
          @click="openModal = !openModal"
        />
        <section class="row-container">
          <h1>Cash Up</h1>
        </section>
        <form action="">
          <label for="name">Cash up done by:</label>
          <input type="text" name="name" id="name" v-model="name" />
        </form>
        <section class="row-container">
          <form action="">
            <input type="num" v-model="floatAmount" class="small" />
            <p>Float amount: ${{ floatAmount }}</p>
            <!-- <button @click.stop.prevent="openFloatModal">Adjust float</button>
            <FloatAdjustment :open="showFloatModal" />
            <p>To Bank: ${{ banking }}</p> -->
          </form>
          <ul>
            <li v-for="(payment, idx) in paymentLabels" :key="idx">
              <div class="table">
                <p>{{ payment }}:</p>
                <p>${{ paymentData[idx].toFixed(2) }}</p>
              </div>
            </li>
            <li>
              <div class="table">
                <p>Total:</p>
                <p>${{ todaysTotal.toFixed(2) }}</p>
              </div>
            </li>
          </ul>
        </section>
        <button class="complete">Complete cash up</button>
      </div>
    </div>
  </teleport>
</template>

<script>
// import FloatAdjustment from "./FloatAdjustment.vue";
export default {
  name: "CashUpModal",
  // components: { FloatAdjustment },
  data() {
    return {
      openModal: false,
      floatAmount: 0,
      showFloatModal: false,
      name: "",
    };
  },
  props: ["open", "paymentData", "paymentLabels"],
  methods: {
    openFloatModal() {
      this.showFloatModal = !this.showFloatModal;
    },
  },

  computed: {
    todaysTotal() {
      return this.paymentData.reduce((acc, curr) => acc + curr, 0);
    },
  },

  watch: {
    async open() {
      this.openModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/wrapper";
.modal {
  .content {
    width: 50%;
    .complete {
      margin: 0;
    }
    .row-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      margin: 0;
      form {
        margin: 0.5rem;
        .small {
          width: 5rem;
          padding: 0.5rem;
          margin: 0.5rem;
          border-radius: 10px;
          border: none;
          box-shadow: var(--box-shadow);
        }
      }
      ul {
        margin: 0.5rem;
        padding: 0;
        li {
          list-style: none;
          margin: 0.25rem 0;
          width: 10rem;
          padding: 0;
          .table {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            p {
              margin: 0;
            }
          }
        }
      }
      button {
        width: 100%;
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
        color: var(--text-colour);
        border: none;
        padding: 0.25rem 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
</style>
